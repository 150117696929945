jQuery(document).ready(function($){
	/*$('.date').datepicker({format:'yyyy-mm-dd',autoClose:true})
	$('.datetime').datetimepicker({format: 'yyyy-mm-dd hh:ii:ss'}); */
	/*Confirm Delete*/
	$('body').on('click','.confirmDelete', function(e){
		return confirm("Are you sure you want to delete?");
	});
	/* Tooltip */
	$('body').tooltip({
		selector: '.tips'
	});

	/*$(".select2").select2({ width:"98%"});
	$(".select-liquid").select2({
		minimumResultsForSearch: "-1",
	});*/
	/*Toggle Content on Change*/
	function toggleCheckboxDiv(inputElem){
		var divToToggle = $('div.'+inputElem.attr('name'));
		if(inputElem.is(':checked')){
			divToToggle.slideDown();
		}else{
			divToToggle.slideUp();
		}
	}
	$('.conditionalCheckHide').each(function(){
		toggleCheckboxDiv($(this));
	}).on('ifChanged', function(){
		toggleCheckboxDiv($(this));
	});
	$.fn.selectToggleDiv = function(){
		$(this).change(function(){
			if($('form.mainForm').length){
				$('form.mainForm').parsley().destroy();
			}
			var thisName = $(this).attr('toggle-name') ? $(this).attr('toggle-name'): $(this).attr('name');
			var thisVal = $(this).val();
			$('.'+thisName).find('select,input').removeAttr('required');
			$('.'+thisName).hide();
			$('.'+thisName+thisVal).show();
			$('.' + thisName + thisVal).find('select,input').attr('required', 'required');
			if($('form.mainForm').length){
				$('form.mainForm').parsley({
					excluded: '.note-image-url, .note-link-url, .note-video-url, .note-image-input'
				});
			}
		});
		/*Setup Page for Filled Model*/
		$(this).trigger('change');
	};
	$('div.alert').not('.alert-important').delay(6000).fadeOut(350);
	$('input[type="checkbox"].minimal, input[type="radio"].minimal').iCheck({
		checkboxClass: 'icheckbox_minimal-blue',
		radioClass: 'iradio_minimal-blue'
	});
	function resizeIt(){
		if(typeof heightElements !== 'undefined') {
			var height = 0;
			$(heightElements).each(function(i,obj) {
				height += $(obj).height();
			});
			$('.main-sidebar, .left-side').css('padding-top', height);
		}
	}
	resizeIt();
	$(window).on("resize load", function(){
		if(this.resizeTO) clearTimeout(this.resizeTO);
		this.resizeTO = setTimeout(function(){
			resizeIt();
		}, 100);
	});

	/*Attribute Sets Page*/
	$('section.content').on('change', 'select[name*="searchUsages"]', function(){
		if($(this).val() !== 'off'){
			$(this).parents('tr').find('td.searchUsagesDepends').fadeIn();
		}else{
			$(this).parents('tr').find('td.searchUsagesDepends').fadeOut();
		}
	});
	/*Trigger Change on page load to show the previously selected Items*/
	$('select[name*="searchUsages"]').trigger('change');
});